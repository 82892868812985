import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GO_TO_LOGIN,
  LOGOUT,
} from "./ActionTypes";
import {
  SET_USER_LODGES,
  SET_USER_NAME,
  SET_SELECTED_PARK,
  SET_SELECTED_LODGE,
  SET_OWNER_ID,
  SET_LOGIN_EMAIL,
  SET_IS_REFERRED_USER,
  SET_MEMBERSHIP_NUMBER,
  SET_CAN_REFER,
  SET_USER_ROLES,
  SET_USER_ROLE_CLAIMS,
} from "../../User/Redux/ActionTypes";
import { SET_LOGIN_METHOD } from "../../Login/Redux/ActionTypes";
import {
  SET_LODGE_DETAILS,
  SET_LODGE_DETAILS_LIST,
} from "../../Information/Redux/ActionTypes";
import { ThunkAction } from "redux-thunk";
import { AppState } from "../../RootReducer";
import { Action } from "redux";
import { Post } from "../../Api/ApiService";
import {
  PostEmailLoginApiCall,
  PostGoogleLoginApiCall,
  PostFacebookLoginApiCall,
} from "../../Api/Calls/PostLoginApiCalls";
import Clients from "../../Constants/Clients";
import sessionStorage from "redux-persist/es/storage/session";
import UserRoles from "../../Constants/UserRoles";
import { Entry } from "contentful";
import { GetEntryCall } from "../../Contentful/Calls/GetEntryCall";
import { getOwnerMembershipAvailability } from "../../User/Redux/ActionCreators";

export const login =
  (
    email: string,
    password: string,
    type: string
  ): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      dispatch({ type: LOGIN_REQUEST, payload: true });

      let userLogin: LoginDTO = {
        Email: email,
        Password: password,
        ClientName: Clients.Owner,
      };

      var loginCall: any;
      switch (type) {
        case "google":
          loginCall = new PostGoogleLoginApiCall(userLogin);
          break;
        case "facebook":
          loginCall = new PostFacebookLoginApiCall(userLogin);
          break;
        default:
          loginCall = new PostEmailLoginApiCall(userLogin);
      }

      Post<LoginResponseDTO>(loginCall)
        .then((response) => {
          dispatch({ type: SET_LOGIN_EMAIL, payload: [response.data.LoginEmail, response.data.PhoneNumber] });
          dispatch({ type: SET_OWNER_ID, payload: response.data.OwnerId });
          dispatch({ type: SET_USER_NAME, payload: [response.data.FirstName, response.data.LastName] });
          dispatch({ type: SET_USER_LODGES, payload: response.data.Lodges });
          dispatch({ type: SET_LOGIN_METHOD, payload: type });
          dispatch({ type: SET_IS_REFERRED_USER, payload: response.data.HasOwnMembership });
          // dispatch({ type: SET_CAN_REFER, payload: response.data.CanRefer });
          let userRoles: UserRoleDTO[] = [];
          response.data.UserRoles.map(userRole => userRoles.push({ name: userRole.Name, contentfulUserTypeId: userRole.ContentfulUserTypeId }));
          dispatch({ type: SET_USER_ROLES, payload: userRoles });
          
          



          if (response.data.Lodges.length > 0) {
            let selectedLodge: SelectedLodgeDTO = {
              LodgeId: response.data.Lodges[0].LodgeId,
              LodgeName: response.data.Lodges[0].Name,
              ContractEndDate: response.data.Lodges[0].ContractEndDate,
              HasSublettings: response.data.Lodges[0].HasSublettings,
              AccountId: response.data.Lodges[0].AccountId,
              AccountNo: response.data.Lodges[0].AccountNo,
              AccountBalance: response.data.Lodges[0].RecentTransactions[0]
                ? response.data.Lodges[0].RecentTransactions[0].Balance
                : undefined,
            };
            let selectedPark = {
              parkCode: response.data.Lodges[0].ParkCode,
              parkName: response.data.Lodges[0].ParkName,
              parkFeatures: response.data.Lodges[0].ParkFeatures,
            };

            if (getState().user && getState().user.lodge) {
              const currentLodgeId = getState().user.lodge.LodgeId;

              if (currentLodgeId && response.data) {
                const lodge = response.data.Lodges.find(
                  (l) => l.LodgeId.toString() === currentLodgeId
                );

                if (lodge) {
                  selectedLodge = {
                    LodgeId: lodge.LodgeId,
                    LodgeName: lodge.Name,
                    ContractEndDate: lodge.ContractEndDate,
                    HasSublettings: lodge.HasSublettings,
                    AccountId: lodge.AccountId,
                    AccountNo: lodge.AccountNo,
                    AccountBalance: lodge.RecentTransactions[0]
                      ? lodge.RecentTransactions[0].Balance
                      : undefined,
                  };
                  selectedPark = {
                    parkCode: lodge.ParkCode,
                    parkName: lodge.ParkName,
                    parkFeatures: lodge.ParkFeatures,
                  };
                }
              }
            }

            dispatch({ type: SET_SELECTED_LODGE, payload: selectedLodge });
            dispatch({ type: SET_SELECTED_PARK, payload: selectedPark });
          } else {
            if (!UserRoles.IsOwner(userRoles)) {
              let selectedPark = {
                parkCode: "TW",
                parkName: "The Warren",
                parkFeatures: ["Events", "Offers", "Memberships", "Activities", "Restaurant", "Spa", "Statement", "Park Info", "News", "FAQs", "ParkInfo", "SubletBookings", "Passes"],
              }

              dispatch({ type: SET_SELECTED_PARK, payload: selectedPark });
              dispatch({ type: SET_MEMBERSHIP_NUMBER, payload: response.data.MembershipNumber });

            }
          }

          if (response.data.LodgeDetails.length > 0) {
            dispatch({
              type: SET_LODGE_DETAILS_LIST,
              payload: response.data.LodgeDetails,
            });
            dispatch({
              type: SET_LODGE_DETAILS,
              payload: response.data.LodgeDetails[0],
            });
          }

          sessionStorage.setItem(
            "access_token",
            response.data.TokenDetails.AccessToken
          );
          dispatch({ type: LOGIN_SUCCESS, payload: true });
        })
        .then(r => {
          dispatch(getOwnerMembershipAvailability());
        })
        .catch((error) => {
          console.log(error);
          let failurePayload = "login.unexpectedErrorMessage";

          if (error && error.response) {
            console.log(error.response);
            switch (error.response.status) {
              case 401:
                    switch (error.response.data.Message.trim()) {
                        case "PARCVU_USER_NOT_FOUND":
                            failurePayload = "register.userNotInParcVu";
                            break;
                        case "USER_LOGIN_FAILED":
                            failurePayload = "login.credentialsIncorrectMessage";
                            break;
                        case "USER_LOGIN_FAILED_NOT_ACTIVATED":
                            failurePayload = "login.credentialsNotActivatedMessage";
                            break;
                    }
                break;
            }
          }

          dispatch({ type: LOGIN_FAILURE, payload: failurePayload });
        });
    };

export const goToLogin =
  (): ThunkAction<void, AppState, null, Action> => async (dispatch) => {
    dispatch({ type: GO_TO_LOGIN });
  };

export const logout =
  (sessionExpired: boolean): ThunkAction<void, AppState, null, Action> =>
    async (dispatch) => {
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("persist:root");
      dispatch({ type: LOGOUT, payload: sessionExpired });
    };

export const getPermissionsFromUserRoles =
  (): ThunkAction<void, AppState, null, Action> =>
    async (dispatch, getState) => {
      let userRoleDTOs: UserRoleDTO[] = getState().user.userRoles;
      let ownerMembershipAvailability: OwnerMembershipAvailabilityDTO = getState().user.ownerMembershipsAvailability;
      let userRoles: Entry<ContentfulUserType>[] = [];

      try {
        for (let role of userRoleDTOs) {
          let userType = await GetEntryCall(role.contentfulUserTypeId) as unknown as Entry<ContentfulUserType>;
          userRoles.push(userType);
        }
        let canReferWithMemberships = userRoles.filter(role =>
          role.fields.referralsRequireAllocatedMemberships
          && role.fields.availableMembershipTypes.length > 0
          && ownerMembershipAvailability.allocated > 0
        ).length > 0;
        let canReferWithOutMemberships = userRoles.filter(role =>
          !role.fields.referralsRequireAllocatedMemberships
        ).length > 0;
        let forcedToMakeMembership = userRoles.filter(role => role.fields.forceCreateMemberForThemselves).length === userRoles.length;
        let array: boolean[] = [canReferWithMemberships, canReferWithOutMemberships, forcedToMakeMembership];
        dispatch({ type: SET_USER_ROLE_CLAIMS, payload: array });
      } catch (e) {
        return false;
      }
    }